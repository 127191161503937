<template>
  <k-field-group language-prefix="expenseClaim.filterFields">
    <k-sidebar
      v-bind="$attrs"
      :save-button-text="$t('global.applyFilter')"
      :title="$t('global.filterTitle', { module: $tc('expenseClaim.title', 2) })"
      fixed
      right
      temporary
      width="400"
      :cancel-button-text="$t('actions.cleanFilters')"
      :with-cancel-button="true"
      v-on="$listeners"
      @click:confirm="handleFilterClick"
      @click:cancel="handleCleanFilter"
    >
      <KSelect
        v-model="form.expenseClaimTypeId"
        :items="types"
        class="col-sm-12"
        field="expenseClaimType"
        item-text="text"
        item-value="value"
      />
      <KSelect
        v-model="form.expenseClaimStatusId"
        :items="statuses"
        class="col-sm-12"
        field="expenseClaimStatus"
        item-text="text"
        item-value="value"
      />
    </k-sidebar>
  </k-field-group>
</template>

<script>
import expenseClaim from '@/application/enums/expenseClaim.json';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KSidebar from '@/components/layout/KSidebar.vue';

export default {
  name: 'ExpenseFilter',
  components: {
    KSelect,
    KSidebar,
    KFieldGroup,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        expenseClaimTypeId: null,
        expenseClaimStatusId: null,
      },
    };
  },
  computed: {
    types() {
      return Object.values(expenseClaim.type).map(typeId => ({
        text: this.$t(`expenseClaim.typeId.${typeId}`),
        value: typeId,
      }));
    },
    statuses() {
      return Object.values(expenseClaim.status).map(statusId => ({
        text: this.$t(`expenseClaim.statusId.${statusId}`),
        value: statusId,
      }));
    },
  },
  watch: {
    filters: {
      handler({
        expenseClaimTypeId,
        expenseClaimStatusId,
      }) {
        this.form = {
          ...this.form,
          expenseClaimTypeId,
          expenseClaimStatusId,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    handleCleanFilter(){
      this.form = {};
      this.$emit('update:filters', this.form);
    },
  },
};
</script>
