<template>
  <KCrudLayout
    :filters.sync="filters"
    :search.sync="searchQuery"
  >
    <template #header>
      {{ $tc('expenseClaim.title', 2) }}
    </template>
    <template #filters="{ attrs, on }">
      <ExpenseFilter
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <template #view.list>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="indexRequest"
        :options.sync="options"
        :search="searchQuery"
        language-prefix="expenseClaim.headers"
        :params="filters"
        resource="expense"
      >
        <template #item.createdAt="{ item: { createdAt } }">
          {{ formatDate(createdAt) }}
        </template>
        <template #item.totalAmount="{ item: { totalAmount, expenseClaimTypeId } }">
          {{ convertAmountToFormat(totalAmount, expenseClaimTypeId) }}
        </template>
        <template #item.expenseClaimTypeId="{ item: { expenseClaimTypeId } }">
          {{ $t(`expenseClaim.typeId.${expenseClaimTypeId}`) }}
        </template>
        <template #item.expenseClaimStatusId="{ item: { expenseClaimStatusId } }">
          {{ $t(`expenseClaim.statusId.${expenseClaimStatusId}`) }}
        </template>
        <template #actions="{ item: { expenseClaimStatusId }, item }">
          <v-btn
            v-if="expenseClaimStatusId === expenseStatus.WAIT_FOR_INTERMEDIARY"
            color="primary"
            depressed
            tile
            @click="approveExpense(item)"
          >
            {{ $t('expenseClaim.actions.approve') }}
          </v-btn>
        </template>
      </k-crud-table>
    </template>
  </KCrudLayout>
</template>

<script>
import { status as expenseStatus, type as expenseType } from '@/application/enums/expenseClaim';
import eventBus from '@/application/eventBus.ts';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { approve, index } from '@/modules/expenseClaim/api';
import ExpenseFilter from '@/modules/expenseClaim/components/ExpenseFilter.vue';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'ExpenseIndex',
  components: {
    ExpenseFilter,
    KCrudTable,
    KCrudLayout,
  },
  mixins: [querySyncMixin],
  data() {
    return {
      searchQuery: '',
      expenseStatus,
      filters: { expenseClaimStatusId: expenseStatus.WAIT_FOR_INTERMEDIARY },
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'candidateName',
          language: 'candidateName',
        },
        {
          value: 'expenseClaimTypeId',
          language: 'expenseClaimType',
        },
        {
          value: 'createdAt',
          language: 'date',
        },
        {
          value: 'totalAmount',
          language: 'amount',
        },
        {
          value: 'expenseClaimStatusId',
          language: 'expenseClaimStatus',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'expenseClaim.index' },
        text: this.$tc('expenseClaim.title', 2),
      },
    ]);
  },
  methods: {
    convertAmountToFormat(amount, type) {
      return this.$n(amount / 100, 'currency');

      //Is this still necessary??
      // if (type === expenseType.KILOMETER) return `${Number.parseFloat(`${amount / 1000}`).toLocaleString()} km`;
      // if (type === expenseType.COST) return this.$n(amount / 100, 'currency');
    },
    indexRequest() {
      return index(...arguments);
    },
    formatDate(date) {
      return dayjs(date).format('LL');
    },
    async approveExpense({ id }) {
      await approve(id);
      this.$refs.table.reload();
    },
  },
};
</script>
